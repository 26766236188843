import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateDataService {

  constructor() {

  }

  kitlist : any  = async _  => {
    var result = false;
    var settings = {
      "crossDomain": true,
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "authorization": "Basic " + localStorage.getItem("keyws")
      },
    }
    let url = localStorage.getItem("key_url") + "/KitList";
    let value = await fetch(url, settings)
      .then(response => response.json())
      .then(response => {
        console.log(response)
      })
  }

}
