import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'index',
    loadChildren: () => import('./pages/index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'view',
    loadChildren: () => import('./pages/view/view.module').then( m => m.ViewPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'insert',
    loadChildren: () => import('./pages/insert/insert.module').then( m => m.InsertPageModule)
  },
  {
    path: 'check',
    loadChildren: () => import('./pages/check/check.module').then( m => m.CheckPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'recordkit',
    loadChildren: () => import('./pages/recordkit/recordkit.module').then( m => m.RecordkitPageModule)
  },
  {
    path: 'new',
    loadChildren: () => import('./pages/new/new.module').then( m => m.NewPageModule)
  },
  {
    path: 'photo',
    loadChildren: () => import('./pages/photo/photo.module').then( m => m.PhotoPageModule)
  },
  {
    path: 'photo2',
    loadChildren: () => import('./pages/photo2/photo2.module').then( m => m.Photo2PageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'record',
    loadChildren: () => import('./pages/record/record.module').then( m => m.RecordPageModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then( m => m.ProjectsPageModule)
  },
  {
    path: 'later',
    loadChildren: () => import('./pages/later/later.module').then( m => m.LaterPageModule)
  },
  
  {
    path: 'config',
    loadChildren: () => import('./pages/config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'insert2',
    loadChildren: () => import('./pages/insert2/insert2.module').then( m => m.Insert2PageModule)
  },
  {
    path: 'useful',
    loadChildren: () => import('./pages/useful/useful.module').then( m => m.UsefulPageModule)
  },
  {
    path: 'protocols',
    loadChildren: () => import('./pages/protocols/protocols.module').then( m => m.ProtocolsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'health',
    loadChildren: () => import('./pages/health/health.module').then( m => m.HealthPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'sample',
    loadChildren: () => import('./pages/sample/sample.module').then( m => m.SamplePageModule)
  },
  {
    path: 'refresh',
    loadChildren: () => import('./pages/refresh/refresh.module').then( m => m.RefreshPageModule)
  },
  {
    path: 'blank-photo',
    loadChildren: () => import('./pages/blank-photo/blank-photo.module').then( m => m.BlankPhotoPageModule)
  },
  {
    path: 'aquatic',
    loadChildren: () => import('./pages/aquatic/aquatic.module').then( m => m.AquaticPageModule)
  },
  {
    path: 'insert3',
    loadChildren: () => import('./pages/insert3/insert3.module').then( m => m.Insert3PageModule)
  },
  {
    path: 'photo-offline',
    loadChildren: () => import('./pages/photo-offline/photo-offline.module').then( m => m.PhotoOfflinePageModule)
  },
  {
    path: 'photo2-offline',
    loadChildren: () => import('./pages/photo2-offline/photo2-offline.module').then( m => m.Photo2OfflinePageModule)
  },
  {
    path: 'insert4',
    loadChildren: () => import('./pages/insert4/insert4.module').then( m => m.Insert4PageModule)
  },
  {
    path: 'sql',
    loadChildren: () => import('./pages/sql/sql.module').then( m => m.SqlPageModule)
  },
  {
    path: 'barcode-protocol',
    loadChildren: () => import('./pages/barcode-protocol/barcode-protocol.module').then( m => m.BarcodeProtocolPageModule)
  },
  {
    path: 'barcodeprotocol',
    loadChildren: () => import('./pages/barcodeprotocol/barcodeprotocol.module').then( m => m.BarcodeprotocolPageModule)
  },
  {
    path: 'aquatic2',
    loadChildren: () => import('./pages/protocol/aquatic/aquatic.module').then( m => m.AquaticPageModule)
  }





];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
