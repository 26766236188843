import { Injectable } from '@angular/core';


@Injectable()
export class PhotosToUploadService {

  public url: string;
  public imagenesTemporales = [];
  constructor() {
    //this.url = environment.url;
   }
   async getPhotos() {
    this.imagenesTemporales = JSON.parse(sessionStorage.photos_temporales || '{}');
    

    return this.imagenesTemporales;
   }

   setPhotos(index: number, photoString: string) {
    this.imagenesTemporales[index] = photoString;
    console.log(this.imagenesTemporales);
    const photos = JSON.parse(sessionStorage.photos_temporales || '{}');
    photos[index] = photoString;
    sessionStorage.setItem('photos_temporales', JSON.stringify(photos));

   }
   clearPhotos(){
     this.imagenesTemporales = JSON.parse( '{}');
     sessionStorage.setItem('photos_temporales', '{}');
   }
}
