import { Injectable } from '@angular/core';


@Injectable()
export class InsertService {

  public currentKitType: string; 
  public currentFamilyKit: string;
  constructor() {
   }
   getCurrentType() {
     console.log("getting photos")
       return this.currentKitType;
   }

   getCurrentFamilyKit() {
       return this.currentFamilyKit;
   }

   setCurrentType(kitType: string) {
    this.currentKitType = kitType;
   }

   setCurrentFamilyKit(familyKit: string) {
       this.currentFamilyKit = familyKit;
   }
}
