import { Component, OnInit,  } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Network } from '@capacitor/network';
const logCurrentNetworkStatus = async () => {
  return await Network.getStatus();
};
function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public onlineOffline: boolean = false;
  public background = sessionStorage.header;

  constructor(
    private router: Router,
    
  ) { 
    
    this.createOnline$().subscribe(isOnline => this.onlineOffline = isOnline);
  }
  
  checkConnection() {
    return 1;
    /*if (this.onlineOffline !== navigator.onLine) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/index']);
      });
    };*/

  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  ngOnInit() {
    //setInterval(this.checkConnection, 5000);
  }
  closeMeNow() {
  }
  goToIndex(){
    this.router.navigateByUrl('index');
  }
  goToLogout(){
    this.router.navigateByUrl('logout');
  }
  goToConfig() {
    this.router.navigateByUrl('config');
  }
}
