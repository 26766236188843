import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { UpdateDataService } from './services/update-data.service';
import { HeaderComponent } from './components/header/header.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { UpdateComponent } from './components/update/update.component';
import { PhotosToUploadService } from 'src/app/services/photos-to-upload.service';
import { InsertService } from 'src/app/services/insert.service';
import { SQLiteService } from './services/sqlite.service';

@NgModule({
  declarations: [AppComponent, HeaderComponent
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [BarcodeScanner,
    AndroidPermissions, UpdateDataService, PhotosToUploadService, InsertService, SQLiteService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent ],
})
export class AppModule { }
